.black-text {
  color: #212121 !important;
}

.primary-text, .link-text {
  color: #FFD242 !important;
}

.white-text {
  color: white !important;
}

.gray-text {
  color: #9E9E9E !important;
}

.pointer, .link-text {
  cursor: pointer;
}

.primary-btn {
  color: black !important;
  background-color: #FFD242 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.black-btn {
  color: white !important;
  background-color: #0A0F1F !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.btn {
  border-radius: 12px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  margin: 8px;
  cursor: pointer;
}

.input-before-icon {
  position: absolute;
  z-index: 9999;
  font-size: larger;
  color: white !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.input-after-icon {
  position: absolute;
  z-index: 9999;
  font-size: larger;
  color: white !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  width: fit-content !important;
}

.bg-red {
  background-color: red !important;
}

.red-text {
  color: red !important;
}

.green-text {
  color: green !important;
}

.ant-upload-drag {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #efefef !important !important;
  border-radius: 18px !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-qnu6hi).ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #FFD242 !important;
}

.MuiDivider-root, .MuiDivider-fullWidth, .MuiDivider-root, .css-ftc7wc-MuiDivider-root {
  display: none !important;
}

.MuiOutlinedInput-notchedOutline .css-igs3ac {
  border: none !important;
  border-width: 0px !important;
}

.css-igs3ac {
  border: none !important;
  border-width: 0px !important;
}

:export {
  primaryColor: #FFD242 !important;
  primaryColorWithAlpha: rgba(255, 210, 66, 0.1019607843) !important;
  mainBgColor: #efefef !important;
  secondBgColor: #ffffff !important;
}

.ShadowContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  background-color: #efefef !important;
  opacity: 1;
}

.BackButton {
  position: absolute;
  top: 20px;
  left: 20px;
  border: 1px solid #48556F;
  padding: 6px;
  font-size: 1.3rem;
  border-radius: 100%;
  display: inline-flex;
  color: white;
  cursor: pointer;
}
.BackButton.relative {
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  padding: 8px;
  font-size: 1.4rem;
  margin-bottom: 20px;
}
@media (max-width: 991px) and (orientation: landscape) {
  .BackButton {
    position: relative;
    top: -5px;
    left: -5px;
    margin-right: auto;
    margin-bottom: 10px;
  }
}

.Input {
  background-color: #F2F2F2 !important;
  border: 0px solid #565656 !important;
  border-radius: 18px !important;
  color: #565656 !important;
  font-size: 16px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  line-height: 1.4 !important;
}
.Input::-moz-placeholder {
  color: inputTextColor;
}
.Input::placeholder {
  color: inputTextColor;
}
.Input:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 210, 66, 0.1019607843) !important;
}
.Input.InputOTP {
  min-width: 50px;
  min-height: 50px;
  margin: 0 3px;
}
@media (max-width: 991px) and (orientation: portrait) {
  .Input.InputOTP {
    min-width: 45px;
    min-height: 45px;
  }
}

h1.Header {
  color: white;
  margin: 0;
  font-size: 2.2rem;
  font-weight: 600;
}

h2.SubTitle {
  color: white;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
}

h6.CardTitle {
  color: white;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
}

h6.CardMatchTitle {
  color: white;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 2px;
  color: #0AA7FF;
}

p.Description {
  color: white;
  margin: 0;
}

.Checkbox {
  font-size: 0.875rem;
}
.Checkbox input {
  margin-top: 5px !important;
  border: 1px solid green;
}
.Checkbox :checked {
  background-color: green;
}

.Button {
  padding: 12px 10px;
  font-size: 20px;
  color: black;
  background-color: #FFD242 !important;
  border: none;
  border-radius: 16px;
  width: 100%;
}
.Button:focus-visible, .Button:focus-within {
  outline: none;
}

.TextDivider {
  --text-divider-gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: white;
}
.TextDivider::before, .TextDivider::after {
  content: "";
  height: 1px;
  background-color: white;
  flex-grow: 1;
}
.TextDivider::before {
  margin-right: var(--text-divider-gap);
}
.TextDivider::after {
  margin-left: var(--text-divider-gap);
}

.UL {
  color: white;
}

.LiveBadge {
  height: -moz-fit-content;
  height: fit-content;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF3C3C;
  border-radius: 18px;
  color: white;
  font-size: 10px;
}

.Score {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: 10px;
  background-color: #181829;
  color: #0AA7FF;
}/*# sourceMappingURL=index.module.css.map */