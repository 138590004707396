@import "../../global";

.ShadowContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    background-color: $mainBgColor;
    opacity: 1;
}

.BackButton {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 1px solid #48556F;
    padding: 6px;
    font-size: 1.3rem;
    border-radius: 100%;
    display: inline-flex;
    color: white;
    cursor: pointer;

    &.relative {
        position: relative;
        top: 0;
        left: 0;
        margin-right: auto;
        padding: 8px;
        font-size: 1.4rem;
        margin-bottom: 20px;
    }

    @media (max-width: 991px) and (orientation: landscape) {
        position: relative;
        top: -5px;
        left: -5px;
        margin-right: auto;
        margin-bottom: 10px;
    }
}

.Input {
    // background-color: #222232 !important;
    background-color: $inputBgColor;
    border: 0px solid $inputTextColor;
    border-radius: 18px !important;
    color: $inputTextColor;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;

    &::placeholder {
        color: inputTextColor;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    }

    &.InputOTP {
        min-width: 50px;
        min-height: 50px;
        margin: 0 3px;

        @media (max-width: 991px) and (orientation: portrait) {
            min-width: 45px;
            min-height: 45px;
        }
    }
}

h1.Header {
    color: white;
    margin: 0;
    font-size: 2.2rem;
    font-weight: 600;
}

h2.SubTitle {
    color: white;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
}

h6.CardTitle {
    color: white;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
}

h6.CardMatchTitle {
    color: white;
    margin: 0;
    font-size: .8rem;
    font-weight: 400;
    letter-spacing: 2px;
    color: #0AA7FF;
}

p.Description {
    color: white;

    // font-size: 16px;
    margin: 0;
}

.Checkbox {
    font-size: 0.875rem;

    input {
        margin-top: 5px !important;
        border: 1px solid green;
    }

    :checked {
        background-color: green;
    }
}

.Button {
    padding: 12px 10px;
    font-size: 20px;
    color: black;

    // background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%);
    background-color: $primaryColor;
    border: none;
    border-radius: 16px;
    width: 100%;

    &:focus-visible,
    &:focus-within {
        outline: none;
    }
}

.TextDivider {
    --text-divider-gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: white;

    &::before,
    &::after {
        content: '';
        height: 1px;
        background-color: white;
        flex-grow: 1;
    }

    &::before {
        margin-right: var(--text-divider-gap);
    }

    &::after {
        margin-left: var(--text-divider-gap);
    }
}

.UL {
    color: white;
}

.LiveBadge {
    height: fit-content;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF3C3C;
    border-radius: 18px;
    color: white;
    font-size: 10px;
}

.Score {
    width: fit-content;
    display: flex;
    border-radius: 10px;
    background-color: #181829;
    color: #0AA7FF;
}